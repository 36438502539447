<template>
  <draggable 
    ref="badgegroup" 
    class="badge-group" 
    :class="{ 'read-only': readOnly }"
    v-model="list" 
    ghost="sort-ghost" 
    @change="change"
    :disabled="readOnly">
    <template v-for="(item, index) in list">
      <slot :item="item" :index="index"></slot>
    </template>
  </draggable>
</template>

<script>

import draggable from 'vuedraggable'

export default {
  name: 'BadgeGroup',
  components: {
      draggable
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: null
    }
  },
  created() {
    this.list = this.value;
  },
  mounted() {
    const self = this;
    this.$refs.badgegroup.$el.onclick = () => {
      self.$emit('click');
    };
  },
  watch: {
    value(newValue) {
      this.list = newValue;
    },
    list(/**newValue*/) {
      this.$emit('input', this.list);
    }
  },
  computed: {
  },
  methods: {
    change(arg) {
      this.$emit('change', arg);
    }
  }
}
</script>

<style lang="scss">
div.badge-group {
  border-radius: 5px;
  border: 1px solid var(--form-control-border);
  font-size: 1rem;
  padding: 3px 8px 0 8px;
  min-height: 32px;
  margin-top: 2px;
  
  &.read-only {
    background-color: var(--form-control-disabled-bg);
    opacity: 1;
  }
}

.sort-ghost {
  opacity: 0.3;
  transition: all 0.7s ease-out;
}
</style>